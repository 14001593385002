import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from '../Header/Navbar';
import FirstBlock from '../FirstBlock/FirstBlock';
import DescontextoPopular from '../Projects/DescontextoPopular';
import GraciasPorVenir from '../Projects/GraciasPorVenir';
import Betty from '../Projects/Betty';

function Home() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<FirstBlock />} />
        <Route path="/1" element={<DescontextoPopular />} />
        <Route path="/2" element={<GraciasPorVenir />} />
        <Route path="/3" element={<Betty />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Home;
