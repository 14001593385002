import React from 'react';
import Card from 'react-bootstrap/Card';
import './Projects.css';

function Betty() {
  return (
    <section className="my-Card d-flex justify-content-center align-items-center">
      <Card className="card-sizes border-0 mx-5">
        <Card.Body className="my-shadow-3 gelasio">
          En mi trabajo, me interesa explorar las relaciones entre las reminiscencias
          de la historia personal y los referentes del espectáculo y/o algunas herramientas
          que se aprenden en la vida de a pie. La vida nocturna ha sido un elemento
          constante que he desarrollado en contextos más informales y que concibo como
          actos de exhibición en sí mismos. En ellos, me envuelve el velo de la romanización,
          el halo de fantasía, encanto o visión naïve del mundo en donde construir elementos
          decorativos, superficiales, hacer uso de la indumentaria y la utilería como
          herramientas de uso para el agenciamiento y la supervivencia. El humor es el placer
          más inmediato y me río por no llorar.
          De estos materiales están fabricados los escenarios abstractos donde habitan
          mis personajes, en cuerpos que flotan en las nubes, en espacios suaves y
          agradables para resistir.
        </Card.Body>
      </Card>
    </section>
  );
}

export default Betty;
