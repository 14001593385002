import React from 'react';
import PropTypes from 'prop-types';
import CarouselScaffold from './Carousel.tsx';
import './FirstBlock.css';

function FirstBlock({ id }) {
  return (
    <section className="d-flex justify-content-center" id={id}>
      <CarouselScaffold />
    </section>
  );
}

FirstBlock.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FirstBlock;
