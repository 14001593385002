import React from 'react';
import Card from 'react-bootstrap/Card';
import './Projects.css';

function GraciasPorVenir() {
  return (
    <section className="my-Card d-flex justify-content-center align-items-center">
      <Card className="card-sizes mx-5">
        <Card.Body className="my-shadow-2 gelasio">
          La estética amateur y las narrativas incompletas
          son el vehículo que he decidido utilizar para abordar
          lo que incomoda, como las imposiciones o normativas sociales
          (el género, los cánones de éxito, la clase, etc). La ambigüedad en e
          l discurso y la autoparodia son lenguajes con los que puedo comunicarme
          y posicionarme estética y/o discursivamente.
        </Card.Body>
      </Card>
    </section>
  );
}

export default GraciasPorVenir;
