/* eslint-disable */
import { useState } from 'react';
import * as B from '../../Assets/img/Backgrounds';
import './FirstBlock.css';

export default function CarouselScaffold() {
  const images = [
    B.Background1, B.Background2, B.Background3, B.Background4,
    B.Background5, B.Background6, B.Background7, B.Background8,
    B.Background9, B.Background10, B.Background11, B.Background12,
    B.Background13,
  ];

  const randomImage = images[Math.floor(Math.random() * images.length)];

  const [index, setIndex] = useState(0);
  const [img, setImg] = useState(randomImage);

  const selectImg = (index: number, images: string[], next = true) => {
    const condition = next ? index < images.length - 1 : index > 0;
    const nextIndex = next ? (condition ? index + 1 : 0)
      : (condition ? index - 1 : images.length - 1);
    setImg(images[nextIndex]);
    setIndex(nextIndex);
  };

  const previous = () => {
    selectImg(index, images, false);
  };

  // const next = () => {
  //   selectImg(index, images);
  // };

  return (
    <>
      <div className="masthead1 d-flex justify-content-center align-items-center">
        <img src={img} onClick={previous} alt="carousel" className="masthead-bg masthead-sizes" />
      </div>
    </>
  );
}
