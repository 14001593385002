import React from 'react';
import Card from 'react-bootstrap/Card';
import './Projects.css';

function DescontextoPopular() {
  return (
    <section className="my-Card d-flex justify-content-center align-items-center">
      <Card className="card-sizes my-shadow-1 mx-5">
        <Card.Body className="volkhov">
          Mi práctica coquetea con la posibilidad que tiene la teoría de ser malinterpretada,
          re-usable y maleable, lo que me permite deformar
          estructuras rígidas desde facetas diversas,
          como la identidad o producción de subjetividad, las afinidades, el humor,
          la pose o actitud
          camp y lo bimbo. He usado formatos como, el dibujo o proto-meme,
          la performance o creación de
          personaje, la exploración en volumen, el vestuario y distintas herramientas del cine y la
          televisión.
        </Card.Body>
      </Card>
    </section>
  );
}

export default DescontextoPopular;
